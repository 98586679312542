.about-page {
  font-family: "Graphik Trial Regular";
}

.about-page h1,
.about-page h2,
.privacy-policy h1 {
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 700;
}
.privacy-policy h4 {
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 20px;
  line-height: 1.3;
  text-transform: capitalize;
}
.about-page h2,
.privacy-policy h2 {
  margin-top: 30px;
}
.about-page p,
.privacy-policy p {
  line-height: 25px;
  font-weight: 500;
  /* font-size: 16px; */
}
.privacy-policy p b,
.privacy-policy p a {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-weight: 600;
}
.image-animate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 20px;
}

.abt_heading {
  font-weight: 800;
  margin: 15px 0px;
  font-size: 24px !important;
}

.image-animate .right,
.image-animate .left,
.image-animate .middle {
  width: 33.333%;
  min-height: 700px;
  height: calc(100vh - 180px);
}

.image-animate .right,
.image-animate .left {
  animation: upAnime 120s infinite linear;
}

.image-animate .middle {
  animation: downAnime 120s infinite linear;
}

.how-its-work-section .block h4 {
  font-family: "Graphik Trial Regular";
  font-weight: 700;
}

.faq-section h2,
.how-its-work-section h2 {
  font-size: 50px;
  font-weight: 600;
  /* letter-spacing: 3px; */
  font-family: "Graphik Trial Regular";
}

.hero-heading h1 {
  line-height: 1;
  font-size: 100px;
  font-family: "Graphik Trial Regular";
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}

.hero-heading p {
  margin: 0;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Graphik Trial Regular";
  font-weight: 400;
  line-height: normal;
}

@keyframes upAnime {
  0% {
    background-position: top;
  }

  100% {
    background-position: bottom;
  }
}

@keyframes downAnime {
  0% {
    background-position: bottom;
  }

  100% {
    background-position: up;
  }
}

@keyframes leftAnime {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

@keyframes rightAnime {
  0% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}

.image-animate .left {
  background: url("../img/LEFT.png") repeat top/cover;
}
.image-animate .middle {
  background: url("../img/MIDDLE.png") repeat top/cover;
}
.image-animate .right {
  background: url("../img/Right.png") repeat top/cover;
}
.abtp-top-section {
  text-align: center;
  padding: 30px 0 80px;
}
.abtp-heading {
  position: relative;
}

.abtp-heading h1 {
  font-family: "Graphik Trial Medium";
  font-size: 70px;
  font-weight: 500;
  margin-bottom: 30px;
}

.abtp-heading p {
  font-family: "Graphik Trial Regular";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 660px;
  margin: 0 auto;
}
.abtp-heading img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.abtp-img-inner {
  gap: 30px;
  display: flex;
  justify-content: space-between;
}
.abtp-ov-inner {
  gap: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.abtp-ov-section {
  padding: 85px 0;
}
.abtp-ov-inner div:last-child {
  width: calc(100% - 190px);
}
.abtp-ov-inner h2 {
  font-family: "Graphik Trial Regular";
  font-size: 80px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  margin: 0;
}
.abtp-ov-inner p {
  font-family: "Graphik Trial Regular";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
}
.abtp-innovation-inner {
  position: relative;
  text-align: center;
  background: #f4f4f4;
  border-radius: 20px;
  padding: 75px 25px;
}

.abt-subhead {
  font-family: "Graphik Trial Regular";
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3;
  margin: 0 0 15px;
}
p.font-24 {
  font-family: "Graphik Trial Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
}
.abtp-innovation-inner p {
  max-width: 70%;
  margin: 0 auto;
  font-family: "Graphik Trial Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
}
.abtp-innovation-inner img {
  position: absolute;
  right: 30px;
  top: 30px;
}
.abtp-innovation-section {
  padding-bottom: 85px;
}
.abtp-rpn-desc {
  text-align: left;
  padding-left: 125px;
}
.abtp-rpn-section {
  padding-bottom: 85px;
}

.abtp-js-section {
  position: relative;
  text-align: center;
  background: #f4f4f4;
  padding: 75px 25px;
  margin-bottom: 85px;
}
.abtp-pop-section {
  padding-bottom: 85px;
  text-align: center;
}
.abtp-pop-section p {
  max-width: 70%;
  margin: 0 auto;
}
.abtp-info-section {
  padding-bottom: 85px;
}
.abtp-info-section a {
  display: inline-block;
  margin-top: 10px;
}
/*  Responsive View */
@media (min-width: 1440px) and (max-width: 1900px) {
}
@media (min-width: 1280px) and (max-width: 1439px) {
}
@media (max-width: 1440px) {
  .abtp-heading h1 {
    font-size: 60px;
  }
  .abtp-ov-inner p {
    font-size: 24px;
    line-height: 1.5;
  }
  .abtp-innovation-inner p {
    max-width: 90%;
  }
  .abtp-rpn-desc {
    text-align: left;
    padding-left: 25px;
  }
  .abtp-pop-section p {
    max-width: 90%;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
}
@media (max-width: 991px) {
  .about-page .image-animate {
    max-height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .abtp-wrapper h1,
  .abtp-wrapper h2,
  .abtp-wrapper h3,
  .abtp-wrapper h4 {
    font-size: 24px;
    font-weight: 600;
  }
  .abtp-wrapper p {
    font-size: 16px;
    text-align: center;
    max-width: 100%;
  }
  .abtp-innovation-inner img {
    display: none;
  }
  .abtp-heading img {
    display: none;
  }
  .abtp-top-section {
    text-align: center;
    padding: 0 0 30px;
  }
  .abtp-ov-section {
    padding: 30px 0;
  }
  .abtp-ov-inner {
    flex-direction: column;
  }
  .abtp-ov-inner div:last-child {
    width: 100%;
  }
  .abtp-img-inner {
    gap: 15px;
  }
  .abtp-innovation-inner {
    border-radius: 10px;
    padding: 30px 25px;
  }
  .abtp-innovation-section {
    padding-bottom: 30px;
  }
  .abtp-rpn-desc {
    text-align: center;
    padding-left: 0;
    padding-top: 25px;
  }
  .abtp-rpn-section {
    padding-bottom: 30px;
  }
  .abtp-js-section {
    padding: 30px 25px;
    margin-bottom: 30px;
  }
  .abtp-pop-section {
    padding-bottom: 30px;
    text-align: center;
  }
  .abtp-info-section {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
}
