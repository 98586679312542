/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Graphik Trial Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Regular'), url('../fonts/graphik-trial/Graphik-Regular-Trial.woff') format('woff');
    }
    
  

    @font-face {
    font-family: 'Graphik Trial Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Thin'), url('../fonts/graphik-trial/Graphik-Thin-Trial.woff') format('woff');
    }
    


    @font-face {
    font-family: 'Graphik Trial Extralight';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Extralight'), url('../fonts/graphik-trial/Graphik-Extralight-Trial.woff') format('woff');
    }
    


    @font-face {
    font-family: 'Graphik Trial Light';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Light'), url('../fonts/graphik-trial/Graphik-Light-Trial.woff') format('woff');
    }
    


    @font-face {
    font-family: 'Graphik Trial Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Medium'), url('../fonts/graphik-trial/Graphik-Medium-Trial.woff') format('woff');
    }
    

/*     

    @font-face {
    font-family: 'Graphik Trial Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Semibold'), url('../fonts/graphik-trial/Graphik-Semibold-Trial.woff') format('woff');
    }
    

 
    

    @font-face {
    font-family: 'Graphik Trial Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Bold'), url('../fonts/graphik-trial/Graphik-Bold-Trial.woff') format('woff');
    }
    

 

    @font-face {
    font-family: 'Graphik Trial Black';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Black'), url('../fonts/graphik-trial/Graphik-Black-Trial.woff') format('woff');
    }
    


    @font-face {
    font-family: 'Graphik Trial Super';
    font-style: normal;
    font-weight: normal;
    src: local('Graphik Trial Super'), url('../fonts/graphik-trial/Graphik-Super-Trial.woff') format('woff');
    } */


    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')

    /* font-family: "Montserrat", sans-serif; */