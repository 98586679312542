*,
body,
html {
    margin: 0
}

.dropdown a,
.font-Figtree,
.profile p,
body {
    font-family: 'Graphik Trial Regular'
}

.container1,
.containerSlider,
.img-gen-v-slider,
.md\:overflow-hidden,
.tab__content {
    overflow: hidden
}

*,
::after,
::before {
    box-sizing: border-box
}

.How-to-gen-img .col img,
img,
video {
    max-width: 100%;
    height: auto
}

.bg-color-14,
.bg-color-F6,
.bg-white {
    --tw-bg-opacity: 1
}

.text-color-14,
.text-color-89,
.text-color-F6,
.text-white {
    --tw-text-opacity: 1
}

.faq .faqTitle a,
.footer ul a,
a {
    text-decoration: none
}

@media(max-width:768px) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media screen and (device-aspect-ratio:2/3) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media screen and (device-aspect-ratio:40/71) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media screen and (device-aspect-ratio:375/667) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media screen and (device-aspect-ratio:9/16) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media only screen and (device-width :375px) and (device-height :812px) and (-webkit-device-pixel-ratio :3) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media only screen and (device-width :375px) and (device-height :667px) and (-webkit-device-pixel-ratio :2) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

@media only screen and (device-width :414px) and (device-height :736px) and (-webkit-device-pixel-ratio :3) {

    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select,
    textarea {
        font-size: 16px !important
    }
}

body,
html {
    height: 100%
}

* {
    padding: 0
}

a {
    color: #000;
    color: inherit;
    text-decoration: inherit
}

body {
    line-height: inherit
}

.about-page,
.contact-us,
.pricing_page,
.privacy-policy {
    padding-top: 80px
}

.google_signup_main {
    width: fit-content !important;
    margin: auto !important;
    padding: 15px 0 !important
}

.registration-link.resendotp {
    width: fit-content;
    margin-left: auto
}

.container {
    max-width: 1440px;
    margin: auto
}

::-moz-selection {
    color: #fff;
    background: #232323
}

::selection {
    color: #fff;
    background: #232323
}

@media(max-width:991px) {
    .mobile-hide {
        display: none !important
    }
}

@media(min-width:992px) {
    .desktop-hide {
        display: none !important
    }
}

.loginbutton {
    padding: 0;
    font-weight: 600;
    background: #000;
    color: #fff;
    width: 105px;
    height: 50px;
    text-align: center;
    border-radius: .5rem;
    display: block;
    border: 0
}

.hidden,
header.header {
    position: fixed;
    top: 30px;
    z-index: 9999;
    width: 100%;
    transition: .5s
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #e4e4e4;
    background-color: #fff;
    max-width: 1440px;
    height: 70px;
    border-radius: 10px;
    box-shadow: 0 21px 100px 0 #0000001A
}

.brand-logo img {
    object-fit: contain;
    margin-top: -2px
}

.header .navigation ul {
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center
}

.header .navigation ul li a,
.header .navigation ul li span {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Graphik Trial Regular';
    padding: 0 10px;
    display: inline-block;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent
}

.header .navigation ul li a.active {
    border-bottom: 2px solid
}

.header .navigation ul li {
    padding: 0 30px
}

.submenu {
    display: none;
    position: absolute;
    right: 0;
    min-width: 200px;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #333
}

.block,
.dropdown:hover .submenu,
.empower-sub,
.md\:block {
    display: block
}

.containerSlider .containerSlider-inner,
.dropdown,
.imgGenraterForm button.theme-btn,
.relative,
.search-generate {
    position: relative
}

.dropdown .submenu ul li {
    padding: 0
}

.How-to-gen-img .col .step,
.How-to-gen-img .title,
.top-main-heading {
    font-family: 'Graphik Trial Medium'
}

.dropdown img {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 3px
}

.language-box img {
    min-width: 18px;
    height: 18px
}

.dropdown svg {
    vertical-align: middle;
    margin-left: 2px
}

.admin-login .submenu ul {
    flex-wrap: wrap;
    padding: 0
}

.admin-login .submenu li {
    min-width: 100%
}

.admin-login .submenu a,
.admin-login .submenu span {
    display: block;
    padding: 6px 15px 10px;
    cursor: pointer
}

.admin-login .submenu a svg,
.admin-login .submenu span svg {
    margin-right: 6px;
    display: inline
}

.admin-login .submenu li .profile {
    display: flex;
    align-items: center;
    background: #000;
    color: #fff;
    padding: 18px;
    border-radius: 7px
}

.border {
    border: 2px solid #000
}

.profile p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 0
}

.profile p span {
    font-size: 14px !important;
    line-height: 22px;
    font-weight: 400;
    color: #fff !important;
    padding: 0 !important
}

.main-banner-inner {
    background: url('../img/bannerHome.png');
    background-size: cover;
    text-align: center;
    padding: 220px 20px 90px;
    position: relative
}

.top-main-heading {
    font-size: 120px;
    line-height: 120px;
    letter-spacing: .01em;
    margin-top: -12px
}

.empower-sub,
.top-sub-heading {
    font-family: 'Graphik Trial Light'
}

.empower-sub {
    font-size: 23px;
    line-height: 27.6px;
    letter-spacing: .14em;
    margin-bottom: 20px
}

.top-sub-heading {
    font-size: 28px;
    color: #000;
    font-weight: 600;
    margin: 0
}

.search-generate input,
.top-sub-head {
    font-family: Montserrat, sans-serif;
    font-size: 16px
}

.top-sub-head {
    font-weight: 400;
    text-align: center;
    color: #000;
    opacity: 60%
}

.search-generate {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 810px;
    margin: auto
}

.footer ul a img,
.gen_loader {
    display: inline
}

.absolute,
.gen_loader,
.mouse,
.mouse-btn,
.search-generate button,
.search-generate img,
.tab input {
    position: absolute
}

.gen_loader {
    height: 25px;
    margin: 0 8px;
    width: 25px;
    z-index: 11
}

.search-generate input {
    height: 60px;
    width: 100%;
    padding: 5px 165px 5px 60px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .1) 0 10px 15px -3px, rgba(0, 0, 0, .05) 0 4px 6px -2px;
    background: #fff;
    border: 1px solid #e4e4e4
}

form.search-generate-form {
    margin-top: 50px
}

.search-generate button {
    border-radius: 10px;
    right: 7.5px;
    font-size: 20px;
    height: 50px;
    background: #000;
    padding: 5px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 0;
    box-shadow: none
}

.search-generate img {
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
    opacity: .3
}

.mouse-btn {
    display: block;
    bottom: 46px;
    left: 0;
    right: 0;
    margin-left: 8px
}

.mouse {
    width: 23px;
    height: 32px;
    border-radius: 20px;
    border: 2px solid #000;
    left: calc(50% - 20px)
}

.mouse:after,
.mouse:before {
    content: "";
    display: block;
    position: absolute
}

.mouse:before {
    content: url('../img/scroll-arrow.svg');
    left: calc(50% - 6px);
    -webkit-animation: 1.2s cubic-bezier(.215, .61, .355, 1) infinite m-1-ball;
    animation: 1.2s cubic-bezier(.215, .61, .355, 1) infinite m-1-ball
}

@-webkit-keyframes m-1-ball {

    0%,
    100%,
    65% {
        opacity: 0
    }

    10%,
    40% {
        opacity: 1
    }

    0% {
        transform: translateY(2px) scale(.7)
    }

    5% {
        transform: scale(.7)
    }

    100%,
    15% {
        transform: scale(1)
    }

    45%,
    65% {
        transform: translateY(8px) scale(.7)
    }
}

@keyframes m-1-ball {

    0%,
    100%,
    65% {
        opacity: 0
    }

    10%,
    40% {
        opacity: 1
    }

    0% {
        transform: translateY(2px) scale(.7)
    }

    5% {
        transform: scale(.7)
    }

    100%,
    15% {
        transform: scale(1)
    }

    45%,
    65% {
        transform: translateY(8px) scale(.7)
    }
}

.How-to-gen-img .container {
    max-width: 1321px
}

.How-to-gen-img {
    text-align: center;
    padding: 100px 0
}

.How-to-gen-img .title {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 15px
}

.How-to-gen-img .txt {
    font-size: 16px;
    font-family: 'Graphik Trial Light';
    color: #6e6e6e
}

.How-to-gen-img .row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px
}

.How-to-gen-img .col {
    width: 33.33%;
    padding: 30px 20px
}

.How-to-gen-img .col:first-child {
    margin-left: 1px
}

.How-to-gen-img .col .dec,
.How-to-gen-img .col .step {
    position: relative;
    left: -.5px
}

.How-to-gen-img .col img {
    margin: auto
}

.How-to-gen-img .col .step {
    font-size: 26px;
    margin-top: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #000
}

.How-to-gen-img .col .dec {
    font-family: 'Graphik Trial Light';
    font-size: 14px;
    font-weight: 400;
    color: #7a7e80;
    line-height: 1.5;
    letter-spacing: 1px
}

.about-img-generater .img-genrater-first .sub-title,
.tab__content p {
    line-height: 24px;
    font-family: 'Graphik Trial Regular';
    font-size: 16px
}

.about-img-generater,
.dropdown-item.active,
.dropdown-item:active {
    background: #000
}

.about-img-generater img {
    max-width: 100%
}

.about-img-generater .row,
.flex,
.md\:flex {
    display: flex
}

.about-img-generater .img-genrater-first {
    color: #fff;
    padding: 60px 70px 0 200px
}

.sora{display:none;}

.about-img-generater .img-genrater-first .sub-title {
    letter-spacing: .2em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 6px
}

.about-img-generater .img-genrater-first .title {
    font-size: 48px;
    line-height: 67px;
    font-weight: 700;
    font-family: 'Graphik Trial Regular';
    letter-spacing: 0
}

.about-img-generater .img-genrater-first .list,
.about-img-generater .img-genrater-first .txt {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400
}

.about-img-generater .img-genrater-first .list {
    margin-top: 2.25rem;
    padding-left: 1.25rem
}

.about-img-generater .img-genrater-first .list li {
    list-style: none;
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    font-family: 'Graphik Trial Regular'
}

.about-img-generater .img-genrater-first .list li svg {
    margin-right: 12px
}

.about-img-generater .img-genrater-first .btn {
    font-size: 18px;
    line-height: 26px;
    border-radius: .5rem;
    border: 2px solid #fff;
    background: 0 0;
    font-family: 'Graphik Trial Regular';
    padding: .875rem 2rem;
    color: #fff;
    margin-top: 22px;
    font-weight: 600
}

.about-img-generater .col:first-child {
    width: 49.4%
}

.about-img-generater .col:nth-child(2) {
    width: 30%
}

.about-img-generater .col:nth-child(3) {
    width: 20.7%
}

.img-gen-v-slider img {
    border-radius: 30px;
    padding: 8px 0;
    height: 244px;
    width: 81%;
    object-fit: cover;
    margin: auto
}

.img-gen-h-slider img {
    border-radius: 30px
}

.img-gen-v-slider {
    max-height: 780px;
    text-align: center
}

.about-img-generater .img-genrater-first .txt {
    margin-top: 12px;
    font-family: 'Graphik Trial Regular'
}

.img-gen-h-slider .slider_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    position: relative;
    top: 40px
}

.img-gen-h-slider .slider {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    animation: 30s cubic-bezier(1, .95, .565, 1) infinite sliding
}

.img-gen-h-slider .slide {
    min-width: 100%;
    height: 100%
}

@keyframes sliding {

    0%,
    20% {
        transform: translateX(0)
    }

    25%,
    45% {
        transform: translateX(-100%)
    }

    50%,
    70% {
        transform: translateX(-200%)
    }

    75%,
    95% {
        transform: translateX(-300%)
    }

    100% {
        transform: translateX(-400%)
    }
}

.tab input {
    opacity: 0;
    z-index: -1
}

.tab__content {
    max-height: 0;
    transition: .35s
}

.tab__close,
.tab__label {
    display: flex;
    border: 1px solid #ccc;
    background-color: #f2f2f2;
    cursor: pointer;
    border-radius: 14px;
    color: #333
}

.tab__label {
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    padding: 20px 30px;
    font-family: 'Graphik Trial Regular'
}

.tab__label svg {
    transition: none
}

.tab input:checked+.tab__label svg {
    transform: rotate(180deg)
}

.tab__content p {
    margin: 0;
    padding: 0 30px 20px;
    font-weight: 400
}

.WorkSteps h2,
.footerEmail a {
    font-family: 'Graphik Trial Medium'
}

.tab__close {
    justify-content: flex-end;
    padding: .5rem 1rem;
    font-size: .75rem
}

.tab input:not(:checked)+.tab__label:hover::after {
    animation: .5s infinite bounce
}

@keyframes bounce {
    25% {
        transform: rotate(90deg) translate(.25rem)
    }

    75% {
        transform: rotate(90deg) translate(-.25rem)
    }
}

.accordion.accordion--radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.accordion.accordion--radio .tab {
    width: 49%;
    height: 100%;
    margin: 12px
}

.accordion.accordion--radio .tab:nth-child(odd) {
    margin-left: 0
}

.accordion.accordion--radio .tab:nth-child(2n) {
    margin-right: 0
}

.tab input:checked+.tab__close,
.tab input:checked+.tab__label,
.tab input:checked~.tab__content {
    border: 1px solid #333
}

.tab input:checked~.tab__content {
    max-height: 40rem;
    border-top: 0;
    border-radius: 0 0 10px 10px
}

.tab input:checked+.tab__close,
.tab input:checked+.tab__label {
    background: 0 0;
    border-bottom: 0;
    border-radius: 10px 10px 0 0
}

.footer {
    background: #000;
    padding-top: 100px;
    color: #fff
}

.faq,
.footer .container .row {
    padding-bottom: 100px
}

.Testimonial .sectionTitle,
.footer .brand-logo,
.footer ul li,
.footerEmail {
    margin-bottom: 30px
}

.footerEmail a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 25px;
    line-height: normal;
    color: #fff
}

.footerEmail a svg {
    margin-right: 10px;
    position: relative;
    top: 5px
}

.footer ul {
    padding: 0;
    list-style: none
}

.footer .footer-social li,
.footer ul li:last-child {
    margin-bottom: 0
}

.footer ul a {
    color: #fff;
    font-family: 'Graphik Trial Regular';
    font-size: 22px;
    font-weight: 400
}

.footer-social li,
.inline-block {
    display: inline-block
}

.footer-social li a {
    display: block !important
}

.footer-social li a img {
    text-align: center;
    display: block;
    margin-right: 10px;
    border-radius: 50%;
    padding: 5px;
    transition: .5s
}

.footer-social li:first-child a:hover img {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)
}

.footer-social li:last-child a:hover img {
    background-color: red
}

.footer .copyright {
    text-align: center;
    border-top: 1px solid #fff;
    padding: 15px;
    font-family: 'Graphik Trial Light';
    font-size: 16px;
    opacity: 45%;
    margin: 0
}

@media (min-width:1450px) {
    .login_page {
        padding-top: 70px !important
    }
}

@media (min-width:1280px) and (max-width:1439px) {
    .container {
        max-width: 1100px;
        margin: auto
    }

    .about-img-generater .img-genrater-first {
        padding: 40px 18px 16px 100px
    }

    .img-gen-v-slider {
        max-height: 537px
    }

    .about-img-generater .img-genrater-first .title {
        font-size: 40px;
        line-height: 50px
    }

    .about-img-generater .img-genrater-first .list {
        margin-top: 1.25rem
    }

    .about-img-generater .img-genrater-first .list li {
        margin-bottom: 10px
    }

    .img-gen-h-slider .slider_container {
        top: 57px
    }

    .accordion.accordion--radio .tab {
        width: 48%
    }
}

@media (min-width:1440px) and (max-width:1900px) {
    .about-img-generater .img-genrater-first {
        padding: 40px 40px 20px 130px
    }

    .img-gen-v-slider {
        max-height: 660px
    }

    .about-img-generater .img-genrater-first .title {
        font-size: 45px;
        line-height: 55px
    }

    .about-img-generater .img-genrater-first .list li {
        margin-bottom: 13px
    }
}

@media (min-width:991px) {
    .mobile-toggle {
        display: none
    }
}

*,
::after,
::before {
    border: 0 solid #e5e7eb;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000
}

::after,
::before {
    --tw-content: ''
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Inter, sans-serif;
    font-feature-settings: normal
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
    background-color: transparent;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0
}

menu,
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #b6b6b6 !important
}

.cursor-pointer,
[role=button],
button {
    cursor: pointer
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
    display: block;
    vertical-align: middle
}

[multiple],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.m-1 {
    margin: .25rem
}

.login_page .logo_login,
.m-auto {
    margin: auto
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.-mt-\[74px\] {
    margin-top: -74px
}

.mb-1\.5 {
    margin-bottom: .375rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-2\.5 {
    margin-top: .625rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-\[18px\] {
    margin-top: 18px
}

.mt-\[30px\],
.swal-text:first-child {
    margin-top: 30px
}

.mt-\[60px\] {
    margin-top: 60px
}

.grid {
    display: grid
}

.h-10,
.md\:h-10 {
    height: 2.5rem
}

.h-5 {
    height: 1.25rem
}

.h-7 {
    height: 1.75rem
}

.h-8 {
    height: 2rem
}

.h-9 {
    height: 2.25rem
}

.h-\[42px\],
.md\:h-\[42px\] {
    height: 42px
}

.h-\[500px\] {
    height: 500px
}

.h-\[54px\] {
    height: 54px
}

.h-max,
.md\:h-max {
    height: -moz-max-content;
    height: max-content
}

.md\:w-10,
.w-10 {
    width: 2.5rem
}

.w-5 {
    width: 1.25rem
}

.w-8 {
    width: 2rem
}

.w-9 {
    width: 2.25rem
}

.w-\[104px\] {
    width: 104px
}

.md\:w-\[157px\],
.w-\[157px\] {
    width: 157px
}

.w-\[270px\] {
    width: 270px
}

.w-\[45\%\] {
    width: 45%
}

.w-full {
    width: 100%
}

.origin-top-right {
    transform-origin: top right
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-nowrap {
    flex-wrap: nowrap
}

.items-start {
    align-items: flex-start
}

.items-center,
.md\:items-center,
.sm\:items-center {
    align-items: center
}

.justify-start {
    justify-content: flex-start
}

.justify-end,
.md\:justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between,
.md\:justify-between {
    justify-content: space-between
}

.gap-1\.5,
.md\:gap-1\.5 {
    gap: .375rem
}

.gap-10 {
    gap: 2.5rem
}

.gap-2 {
    gap: .5rem
}

.gap-2\.5 {
    gap: .625rem
}

.gap-3,
.md\:gap-3 {
    gap: .75rem
}

.gap-4 {
    gap: 1rem
}

.gap-5 {
    gap: 1.25rem !important
}

.gap-7,
.md\:gap-7 {
    gap: 1.75rem
}

.overflow-auto {
    overflow: auto
}

.whitespace-nowrap {
    white-space: nowrap
}

.break-words {
    overflow-wrap: break-word
}

.rounded-\[10px\] {
    border-radius: 10px
}

.rounded-\[14px\] {
    border-radius: 14px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-md {
    border-radius: .375rem
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.border {
    border-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-color-89 {
    --tw-border-opacity: 1;
    border-color: rgb(137 137 137 / var(--tw-border-opacity))
}

.border-color-DF {
    --tw-border-opacity: 1;
    border-color: rgb(223 223 223 / var(--tw-border-opacity))
}

.bg-color-14 {
    background-color: rgb(20 20 20 / var(--tw-bg-opacity))
}

.bg-color-F6 {
    background-color: rgb(246 243 242 / var(--tw-bg-opacity))
}

.bg-white {
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-top {
    background-position: top
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}

.py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px
}

.pb-3 {
    padding-bottom: .75rem
}

.pb-6 {
    padding-bottom: 1.5rem
}

.pb-\[20px\] {
    padding-bottom: 20px
}

.pb-\[30px\] {
    padding-bottom: 30px
}

.pl-5 {
    padding-left: 1.25rem
}

.pr-5 {
    padding-right: 1.25rem
}

.pt-12 {
    padding-top: 3rem
}

.pt-14 {
    padding-top: 3.5rem
}

.pt-\[237px\] {
    padding-top: 237px
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-14 {
    font-size: 14px;
    line-height: 22px
}

.text-16 {
    font-size: 16px;
    line-height: 24px
}

.text-18 {
    font-size: 18px;
    line-height: 26px
}

.text-20 {
    font-size: 20px;
    line-height: 28px
}

.text-36 {
    font-size: 36px;
    line-height: 50px
}

.leading-6,
.text-base {
    line-height: 1.5rem
}

.text-\[16px\] {
    font-size: 16px
}

.text-base {
    font-size: 1rem
}

.font-bold {
    font-weight: 700
}

.font-medium {
    font-weight: 500
}

.font-normal {
    font-weight: 400
}

.uppercase {
    text-transform: uppercase
}

.tracking-\[0\.2em\] {
    letter-spacing: .2em
}

.text-color-14 {
    color: rgb(20 20 20 / var(--tw-text-opacity))
}

.text-color-89 {
    color: rgb(137 137 137 / var(--tw-text-opacity))
}

.text-color-F6 {
    color: rgb(246 243 242 / var(--tw-text-opacity))
}

.text-white {
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.md\:right-0 {
    right: 0
}

.md\:right-1 {
    right: .25rem
}

.md\:top-14 {
    top: 3.5rem
}

.md\:mt-0 {
    margin-top: 0
}

.md\:h-11 {
    height: 2.75rem
}

.md\:w-1\/5 {
    width: 20%
}

.md\:w-11 {
    width: 2.75rem
}

.md\:w-\[600px\] {
    width: 600px
}

.md\:w-max {
    width: -moz-max-content;
    width: max-content
}

.md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.md\:flex-row {
    flex-direction: row
}

.md\:gap-6 {
    gap: 1.5rem
}

.md\:gap-8 {
    gap: 2rem
}

.md\:bg-transparent {
    background-color: transparent
}

.md\:px-0 {
    padding-left: 0;
    padding-right: 0
}

.md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}

.md\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px
}

.md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.md\:pb-0 {
    padding-bottom: 0
}

.md\:pl-10 {
    padding-left: 2.5rem
}

.md\:pr-10 {
    padding-right: 2.5rem
}

.md\:pt-0 {
    padding-top: 0
}

.md\:pt-\[167px\] {
    padding-top: 167px
}

.md\:pt-\[66px\] {
    padding-top: 66px
}

@media (min-width:1024px) {
    .lg\:mt-16 {
        margin-top: 4rem
    }

    .lg\:mt-5 {
        margin-top: 1.25rem
    }

    .lg\:w-1\/2 {
        width: 50%
    }

    .lg\:w-\[430px\] {
        width: 430px
    }

    .lg\:flex-nowrap {
        flex-wrap: nowrap
    }

    .lg\:justify-center {
        justify-content: center
    }

    .lg\:gap-5 {
        gap: 1.25rem
    }

    .lg\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .lg\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .lg\:pl-16 {
        padding-left: 4rem
    }

    .lg\:text-48 {
        font-size: 48px;
        line-height: 67px
    }
}

@media (min-width:1152px) {
    .xl\:relative {
        position: relative
    }

    .xl\:mb-0 {
        margin-bottom: 0
    }

    .xl\:block {
        display: block
    }

    .xl\:w-\[44\%\] {
        width: 44%
    }

    .xl\:w-\[570px\] {
        width: 570px
    }

    .xl\:w-max {
        width: -moz-max-content;
        width: max-content
    }

    .xl\:flex-row {
        flex-direction: row
    }
}

@media (min-width:1280px) {
    .\32xl\:w-\[34\%\] {
        width: 34%
    }

    .\32xl\:w-\[700px\] {
        width: 700px
    }

    .\32xl\:justify-start {
        justify-content: flex-start
    }

    .\32xl\:pl-0 {
        padding-left: 0
    }

    .\32xl\:pl-16 {
        padding-left: 4rem
    }

    .\32xl\:pt-\[154px\] {
        padding-top: 154px
    }
}

@media (min-width:1366px) {
    .\34xl\:w-\[770px\] {
        width: 770px
    }
}

@media (min-width:1440px) {
    .\36xl\:mt-40 {
        margin-top: 10rem
    }

    .\36xl\:w-\[278px\] {
        width: 278px
    }

    .\36xl\:text-48 {
        font-size: 48px;
        line-height: 67px
    }
}

@media (min-width:1600px) {
    .\37xl\:w-\[1000px\] {
        width: 1000px
    }

    .\37xl\:pl-32 {
        padding-left: 8rem
    }
}

@media (min-width:1680px) {

    .\38xl\:pl-40,
    .\38xl\:px-40 {
        padding-left: 10rem
    }

    .\38xl\:px-40 {
        padding-right: 10rem
    }
}

@media (min-width:1920px) {
    .\39xl\:px-\[310px\] {
        padding-left: 310px;
        padding-right: 310px
    }

    .\39xl\:pl-\[310px\] {
        padding-left: 310px
    }
}

.login_page {
    background: url('../img/Login+Bg.webp') #f6f3f2;
    padding-top: 10px;
    min-height: 100vh;
    /* overflow: hidden */
}

.login_page .loginBox {
    background: #fff;
    max-width: 550px;
    margin: 30px auto auto;
    padding: 40px;
    border-radius: 30px
}

section.login_page img {
    object-fit: contain;
    margin: auto;
    display: table
}

.loginBox .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700
}

.login_page .loginBox .google-btn {
    background: #141414;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 30px 0;
    padding: 11px;
    border-radius: 12px;
    position: relative;
    justify-content: center
}

.bw_contact_address_box {
    padding: 0 47px 17px;
    background: #f7f7f7;
    margin-top: 30px;
    margin-left: 30px
}

.bw_contat_icon_inner {
    margin: 30px 0;
    display: flex;
    font-size: 15px
}

.forgetpassword,
.login_page .loginBox .form-div input,
.login_page .loginBox .form-div label,
.or-line {
    font-size: 14px;
    line-height: 22px;
    font-family: 'Graphik Trial Regular'
}

.bw_contact_address_box h3 {
    padding-top: 30px;
    font-size: 25px;
    font-weight: 700
}

.bw_contat_icon_inner svg {
    margin-right: 16px;
    font-size: 22px
}

.bw_add_box h6 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 12px
}

.login_page .loginBox .google-btn svg {
    position: absolute;
    left: 5px
}

.or-line {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px
}

.or-line:after,
.or-line:before {
    content: "";
    width: 27%;
    display: block;
    height: 1px;
    background: #ddd
}

.login_page .loginBox .form-div label {
    display: block;
    font-weight: 500
}

.login_page .loginBox .form-div input {
    display: block;
    width: 100%;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin: 10px 0;
    height: 45px
}

.forgetpassword {
    float: right;
    display: block;
    font-weight: 500;
    text-decoration-line: underline;
    color: #212529;
    position: relative;
    z-index: 1;
}

.signIn-btn {
    background: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    margin: 30px 0;
    padding: 11px;
    border-radius: 12px;
    font-family: 'Graphik Trial Regular';
    border: 0
}

.registration-link,
.tnc-link {
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'Graphik Trial Regular'
}

.tnc-link {
    color: #888
}

.registration-link a,
.tnc-link a {
    text-decoration-line: underline;
    font-weight: 600
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.magic-bg {
    background: linear-gradient(180deg, #000 0, #000 100%) !important
}

.text-\[13px\] {
    font-size: 13px
}

.h-1 {
    height: .25rem
}

.faq .accordion .accordion-item {
    border: 1px solid #e9e9e9 !important;
    cursor: pointer;
    border-radius: 14px;
    color: #333;
    margin-bottom: 20px
}

.faq .accordion .active.accordion-item {
    border: 1px solid #000 !important
}

.accordion-button:not(.collapsed)::after {
    content: '-';
    font-size: 50px;
    line-height: 17px !important;
    font-weight: 500
}

.accordion-button::after {
    background-image: none;
    width: 30px;
    height: 30px;
    background-color: #000;
    color: #fff;
    content: '+';
    border-radius: 50%;
    line-height: 27px !important;
    padding: 0;
    text-align: center;
    font-family: 'Graphik Trial Extralight';
    font-weight: 600;
    font-size: 25px
}

button#rcc-decline-button {
    background: #000 !important;
    padding: 10px 34px !important;
    border-radius: 6px !important;
}

button#rcc-confirm-button {
    background: #fff !important;
    padding: 10px 34px !important;
    border-radius: 6px !important;
}

.swal-text,
.swal-title {
    font-family: 'Graphik Trial Regular';
    font-weight: 600;
    color: #222;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: center
}

.swal-text {
    font-size: 16px;
    font-weight: 500
}

.swal-footer {
    text-align: center;
    margin-top: 0
}

.swal-button,
.swal-button:focus,
.swal-button:hover,
.swal-button:not([disabled]):hover {
    color: #fff;
    background-color: #1f1f1f;
    border-radius: 8px;
    min-width: 130px;
    font-weight: 400;
    outline: 0;
    box-shadow: none
}

.swal-icon {
    transform: scale(.5);
    margin: 0 auto !important
}

.swal-modal {
    width: 350px
}

@media (min-width:1280px) and (max-width:1439px) {
    .login_page {
        padding-top: 30px;
        overflow: auto;
        padding-bottom: 50px
    }

    .login_page .loginBox {
        margin-top: 30px
    }
}

@media (max-width:991px) {

    .dropdown .submenu a,
    .dropdown .submenu li>span,
    .mobile-toggle ul>li>a {
        text-decoration: none;
        color: #000;
        font-weight: 400;
        font-family: 'Graphik Trial Regular'
    }

    .dropdown svg {
        margin-right: 6px;
        display: inline-block
    }

    footer,
    section {
        overflow: hidden
    }

    .header .container {
        padding: 10px
    }

    .header .navigation ul {
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        background: #fff;
        padding: 10px;
        text-align: center;
        top: 52px
    }

    .header .navigation ul li {
        display: table;
        margin: 20px auto
    }

    .mobile-toggle ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0
    }

    .loginbutton {
        width: 90px;
        height: 40px;
        margin-left: 55px
    }

    .mobile-toggle ul>li>a {
        padding: 0 10px 0 0;
        font-size: 16px
    }

    .mobile-toggle ul>li:last-child>a {
        padding: 0
    }

    .top-main-heading {
        font-size: 60px;
        line-height: normal;
        margin-top: 0
    }

    .empower-sub {
        font-size: 13px;
        margin: -11px 0 0
    }

    .top-sub-heading {
        font-size: 18px;
        color: #000;
        font-weight: 600;
        margin-bottom: 0
    }

    form.search-generate-form {
        margin-top: 20px
    }

    .top-sub-head {
        font-size: 16px;
        margin-bottom: 25px
    }

    .search-generate button {
        font-size: 16px;
        padding: 0 16px
    }

    .main-banner-inner {
        padding: 135px 10px 75px
    }

    .How-to-gen-img,
    .faq {
        padding: 50px 0
    }

    .How-to-gen-img .col {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 50px
    }

    .How-to-gen-img .row {
        display: block
    }

    .How-to-gen-img .title {
        font-size: 30px
    }

    .How-to-gen-img .col .step {
        font-size: 20px
    }

    .about-img-generater .col:nth-child(2),
    .about-img-generater .col:nth-child(3),
    .img-gen-h-slider,
    .img-gen-v-slider,
    .note {
        display: none
    }

    .about-img-generater .col:first-child {
        width: 100%
    }

    .about-img-generater .img-genrater-first {
        padding: 50px 20px
    }

    .about-img-generater .img-genrater-first .title {
        font-size: 30px;
        line-height: 1.3
    }

    .about-img-generater .img-genrater-first .list,
    .about-img-generater .img-genrater-first .txt {
        font-size: 16px
    }

    .faq .title {
        font-size: 30px;
        margin-bottom: 20px;
        padding: 0 50px;
        line-height: 1.5
    }

    .accordion.accordion--radio .tab {
        width: 100%;
        height: 100%;
        margin: 8px !important
    }

    .tab__label {
        font-size: 18px;
        line-height: 26px;
        padding: 14px 25px 14px 17px
    }

    .tab__content p {
        padding: 0 25px 14px 17px
    }

    .newslatter h1 {
        font-size: 30px;
        line-height: 45px;
        margin-top: 5px
    }

    .footer .copyright,
    .newslatter p {
        font-size: 14px
    }

    .newslatter p {
        line-height: 20px;
        margin: 5px 0 17px
    }

    section.newslatter form {
        display: flex;
        margin: 10px
    }

    .newslatter {
        padding: 0 0 50px
    }

    .footer .row .col {
        min-width: 48%;
        padding: 10px
    }

    .footer .row {
        flex-wrap: wrap
    }

    .footer .row .col .footer-social {
        display: flex
    }

    .footer .row .col ul a {
        font-size: 20px;
        margin: 0
    }

    .footer-social li a svg {
        width: 30px;
        height: 30px;
        padding: 6px
    }

    .footer ul li {
        margin-bottom: 22px
    }

    .footer-social li {
        margin-right: 10px
    }

    .dropdown .submenu a,
    .dropdown .submenu li>span {
        font-size: 16px;
        display: block;
        padding: 6px 15px 10px !important;
        margin-right: -.5px
    }

    .login_page {
        padding-top: 30px;
        overflow: auto;
        padding-bottom: 30px
    }

    .login_page .loginBox {
        padding: 20px;
        margin-top: 22px;
        border-radius: 20px
    }

    .brand-logo img {
        max-width: 160px;
        object-fit: contain;
        margin-left: 4px;
        margin-top: 0
    }

    .imgGenraterForm .title {
        font-size: 19px;
        margin-bottom: 20px
    }
}

.header .front-account,
.mobile-toggle .front-account {
    background: #000;
    color: #fff;
    display: block;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    padding: 0;
    justify-content: center;
    border-radius: 50px;
    align-items: center
}

.mobile-toggle .front-account {
    display: flex;
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    line-height: 30px
}

.header .navigation ul li .submenu li .profile .front-account,
.mobile-toggle .submenu .profile .front-account {
    background: #fff;
    color: #000;
    padding: 0
}

.top_heighlights {
    text-align: center;
    margin: 0;
    background: #000;
    color: #fff;
    padding: 6px;
    font-family: 'Graphik Trial Regular';
    font-weight: 500;
    font-size: 14px
}

@media(max-width:768px) {
    .top_heighlights {
        font-size: 13px;
        line-height: 16px
    }

    .header .mobile-toggle ul .admin-login {
        margin-right: 15px
    }

    .login_page {
        padding-top: 8%;
        background-size: contain
    }

    .search-generate input {
        font-size: 14px
    }

    .top-sub-head {
        display: none
    }

    .accordion .accordion-body {
        padding: 7px 20px 18px !important;
        font-size: 15px
    }

    .bw_contact_address_box {
        margin-left: 0
    }
}

.Testimonial,
.WorkSteps {
    padding: 100px 0
}

.containerSlider .img-slider {
    display: flex;
    white-space: nowrap;
    position: relative;
    margin-inline: auto;
    animation: 60s linear infinite slide
}

.containerSlider .img-slider .img-box {
    margin: 0 0px
}

.containerSlider .img-slider img {
    margin: auto 5px;
    min-width: 270px;
    height: 450px;
    object-fit: cover
}

.containerSlider .containerSlider-inner::before {
    content: "";
    position: absolute;
    top: -65px;
    left: -2%;
    width: 105%;
    height: 100px;
    background: #fff;
    border-radius: 100%;
    z-index: 1
}

.containerSlider .containerSlider-inner::after {
    content: "";
    position: absolute;
    bottom: -42px;
    left: -1%;
    width: 105%;
    height: 100px;
    background: #fff;
    border-radius: 100%;
    z-index: 1
}

.WorkSteps {
    background: url('../img/bgbanner.png') center/cover;
    color: #fff;
    font-family: 'Graphik Trial Regular'
}

.WorkSteps h2 {
    font-size: 50px;
    margin-bottom: 50px
}

.WorkSteps h3 {
    font-size: 30px;
    font-weight: 500
}

.WorkSteps p {
    font-size: 16px;
    font-family: 'Graphik Trial Light';
    margin-top: 20px;
    line-height: 30px
}

.WorkSteps .nav-item button {
    font-family: 'Graphik Trial Light';
    font-size: 24px;
    color: #fff;
    box-shadow: none !important;
    border: 0;
    border-bottom: 1px solid transparent;
    padding: 0 0 10px;
    margin-right: 50px;
    font-weight: 500
}

.WorkSteps .nav-item button.active,
.WorkSteps .nav-item button:focus,
.WorkSteps .nav-item button:hover {
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid #fff;
    font-weight: 700
}

.WorkSteps .nav-tabs {
    border-color: #ffffff71;
    padding: 0
}

.Testimonial .sectionTitle h2 {
    font-family: 'Graphik Trial Medium';
    font-size: 50px;
    text-align: center;
    color: #000;
    margin-bottom: 15px
}

.Testimonial .sectionTitle p {
    font-family: 'Graphik Trial Light';
    font-size: 16px;
    text-align: center;
    color: #7a7e80
}

.Testimonial .Testimonial_logos_slide {
    white-space: nowrap;
    animation: 200s linear infinite slide
}

.Testimonial .reavers-slider .Testimonial_logos_slide {
    white-space: nowrap;
    animation: 200s linear infinite reverse reavers-slider
}

.Testimonial_logos :hover .Testimonial_logos_slide {
    animation-play-state: paused
}

.Testimonial .card {
    white-space: wrap;
    min-width: 525px;
    height: 245px;
    margin: 10px;
    background: #f4f4f8;
    border-radius: 15px;
    border: 0;
    padding: 30px
}

.Testimonial_logos::after,
.Testimonial_logos:before {
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    width: 20%;
    height: 308px
}

.Testimonial .card>p {
    margin: 15px 0;
    font-family: 'Graphik Trial Regular';
    font-size: 16px;
    line-height: 28px;
    color: #000;
    letter-spacing: 0
}

.Testimonial .card>p a {
    font-family: 'Graphik Trial Medium';
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    display: inline;
    color: #000
}

.Testimonial .card .userImg img {
    border-radius: 50%;
    margin-right: 10px
}

.Testimonial .card .UserName h4 {
    font-family: 'Graphik Trial Medium';
    font-size: 20px;
    color: #000
}

.Testimonial .card .UserName p {
    font-family: 'Graphik Trial Regular';
    font-size: 14px;
    color: #000;
    opacity: 50%
}

.Testimonial_logos:before {
    background: linear-gradient(to right, #fff, transparent)
}

.Testimonial_logos::after {
    background: linear-gradient(to left, #fff, transparent);
    right: 0
}

@keyframes slide {
    from {
        transform: translateX(0)
    }

    to {
        transform: translateX(-100%)
    }
}

@keyframes reavers-slider {
    from {
        transform: translateX(0)
    }

    to {
        transform: translateX(-100%)
    }
}

.faq .accordion {
    padding-left: 100px
}

.faq .faqTitle {
    position: sticky;
    top: 130px
}

.faq .faqTitle h2 {
    font-family: 'Graphik Trial Medium';
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: 20px
}

.faq .faqTitle a,
.faq .faqTitle p {
    font-weight: 400;
    font-family: 'Graphik Trial Regular'
}

.faq .faqTitle p {
    font-size: 16px;
    color: #7a7e80;
    margin-bottom: 30px
}

.faq .faqTitle a {
    display: inline-block;
    font-size: 20px
}

.faq .accordion .accordion-header button {
    font-size: 22px;
    font-family: 'Graphik Trial Medium';
    align-items: center
}

.faq .accordion .accordion-body {
    font-family: 'Graphik Trial Regular';
    font-size: 16px;
    color: #7a7e80;
    line-height: 25px;
    padding: 0 55px 25px
}

@media (min-width:1280px) and (max-width:1439px) {

    .How-to-gen-img .container,
    .header .container {
        max-width: 1100px
    }

    .main-banner-inner {
        padding: 180px 20px 100px
    }

    .How-to-gen-img {
        padding: 60px 0
    }

    .top-main-heading {
        font-size: 90px;
        line-height: 110px
    }

    .top-sub-heading {
        font-size: 35px;
        line-height: normal;
        margin: 0
    }

    .empower-sub {
        font-size: 20px;
        line-height: 18.6px
    }

    .How-to-gen-img .title,
    .Testimonial .sectionTitle h2,
    .WorkSteps h2,
    .faq .faqTitle h2 {
        font-size: 40px
    }

    .How-to-gen-img .col .step,
    .WorkSteps .nav-item button,
    .faq .accordion .accordion-header button,
    .footer ul a {
        font-size: 20px
    }

    .WorkSteps h3 {
        font-size: 24px
    }

    .footer ul a {
        white-space: nowrap
    }

    .footer .copyright {
        font-size: 16px
    }

    .footerEmail a {
        font-size: 22px
    }

    .brand-logo img {
        max-width: 300px;
        object-fit: contain
    }

    .footer-social li a img {
        width: 80%
    }
}

@media (max-width:991px) {
    header.header {
        top: 10px
    }

    .header .container {
        width: 95%;
        padding: 10px;
        height: auto
    }

    .containerSlider .img-slider img {
        min-width: 125px;
        height: 250px
    }

    .containerSlider .containerSlider-inner::after {
        bottom: -47px;
        left: -9%;
        width: 122%;
        height: 71px
    }

    .containerSlider .containerSlider-inner::before {
        width: 122%;
        left: -9%;
        top: -75px
    }

    .WorkSteps h2 {
        font-size: 35px;
        margin-bottom: 30px
    }

    .Testimonial .sectionTitle h2 {
        font-size: 35px;
        margin-bottom: 0
    }

    .Testimonial,
    .WorkSteps {
        padding: 50px 0
    }

    .WorkSteps .nav-item button {
        font-size: 20px;
        white-space: nowrap
    }

    .WorkSteps .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto
    }

    .textBox {
        padding-left: 0 !important;
        padding-top: 30px
    }

    .Testimonial .card {
        min-width: 250px;
        width: 250px;
        height: auto;
        border-radius: 10px;
        padding: 18px
    }

    .Testimonial .card>p {
        margin: 5px 0;
        font-size: 14px;
        line-height: 23px
    }

    .faq .faqTitle h2,
    .faq .faqTitle p {
        margin-bottom: 10px
    }

    .Testimonial .card>p a {
        font-size: 14px
    }

    .Testimonial .card .userImg img {
        width: 30px;
        height: 30px;
        margin-right: 7px
    }

    .Testimonial .card .UserName h4 {
        font-size: 14px;
        margin-bottom: 0
    }

    .Testimonial .card .UserName p {
        font-size: 12px;
        margin-bottom: 0
    }

    .Testimonial .stars {
        width: 100px;
        height: auto
    }

    .faq .accordion {
        padding-left: 0;
        margin-top: 20px
    }

    .faq .accordion .accordion-header button {
        font-size: 18px;
        line-height: normal
    }

    .faq .faqTitle h2 {
        font-size: 35px;
        line-height: normal
    }

    .faq .faqTitle a {
        padding: 10px 20px !important;
        font-size: 16px
    }

    .footerEmail a {
        font-size: 25px
    }

    .footer .brand-logo img {
        max-width: 250px;
        margin-left: 0
    }

    .footer {
        padding: 50px 0 0
    }

    .footer .container .row {
        padding: 0 0 50px
    }

    .footer .container .row .col.p-0 {
        padding-left: 15px !important
    }

    .footer-social li a img {
        width: 90%;
        margin-right: 0;
        padding: 5px
    }
}

@media (min-width:991px) and (max-width:1279px) {
    .about-img-generater .img-genrater-first {
        padding: 40px
    }

    .img-gen-v-slider {
        max-height: 680px
    }

    .faq {
        padding: 70px 0
    }

    .accordion.accordion--radio .tab {
        width: 48%
    }

    .header .container {
        max-width: 95%
    }

    .main-banner-inner {
        padding: 155px 20px 80px
    }

    .faq .accordion {
        padding-left: 20px
    }
}

@media(max-width:389px) {
    .loginbutton {
        width: 80px;
        height: 35px;
        margin-left: 35px;
    }
}

.WorkSteps .tab-pane img {
    width: auto !important;
    max-height: 400px;
}

.offcanvas-body ul li {
    margin-bottom: 17px;
    font-size: 17px;
}

.dashboardBtn {
    width: 138px;
    height: 43px;
}

.offcanvas.offcanvas-start.show {
    z-index: 9999;
    background: #f2f2f2;
}

.footerEmail.mobileHead a {
    color: #000;
    font-size: 16px;
}

.footerEmail.mobileHead a svg path {
    fill: #000;
}

.footerEmail.mobileHead a svg {
    fill: #000;
    width: 22px;
    height: 22px;
    top: 2px;
}

.footer-social.mobileHead li a img {
    filter: invert(1) brightness(1);
    width: 70%;
    padding: 0;
}

.footer-social.mobileHead li {
    margin: 0;
}

.mobileOffcanvas {
    border: 1px solid #ddd;
    margin: 20px;
    border-radius: 10px;
    padding: 10px;
    background: #fff;
}

.mobile-head-bottom {
    border-top: 1px solid #ddd;
    padding-top: 15px;
    margin-top: 20px;
}

.footerEmail.mobileHead {
    margin: 0;
}

.mobile-head-bottom {}

body.modal-open .App {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(4px);
}

.socialShareIconBox {
    position: absolute;
    top: 45px;
    left: -30px;
    display: flex;
    background: #ddd;
    padding: 10px 5px;
    border-radius: 8px;
    box-shadow: 0 0 10px #e4e4e4;
}

.socialShareIconBox button {
    margin: 0 !important;
    padding: 0 8px !important;
}

.socialShareIconBox button svg {
    margin: 0 !important;
}

.socialShareIconBox:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #ddd;
    position: absolute;
    top: -8px;
    left: 42%;
}

@media(min-width:1280px) and (max-width:1439px) {
    .socialShareIconBox button {
        padding: 0px 3px !important;
    }

    .socialShareIconBox {
        padding: 5px;
    }
}

@media(max-width:768px) {
    .socialShareIconBox button {
        padding: 0px 3px !important;
    }

    .top_heighlights {
        font-size: 13px;
        line-height: 16px
    }

    .socialShareIconBox {
        padding: 5px;
        top: auto;
        bottom: 45px;
        left: auto;
        right: 0;
    }

    .socialShareIconBox:before {
        border-top: 10px solid #ddd;
        border-bottom: 0;
        top: auto;
        bottom: -8px;
        left: auto;
        right: 16%;
    }
}

.modal-body {
    min-height: 400px;
}

@media(max-width:380px) {

    .imgDetailBox .theme-btn,
    .imgDetailBox .theme-btn[type='submit'],
    .content-box .btns-group button {
        padding: 10px 6px !important;
    }
}

.passshow {
    position: absolute;
    width: 25px;
    right: 15px;
    top: 42px;
    cursor: pointer;
}

/* ==============================Maintanance============================= */

.maintenancePage .maintenance {
    text-transform: uppercase;
    font-size: 3rem;
}

.maintenancePage {
    background-color: #000;
    color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    font-family: "Inconsolata", monospace;
}

.maintenancePage .what-is-up {
    max-width: 1000px;
    margin: auto;
    display: block;
    text-align: center;
}

.maintenancePage .what-is-up .spinny-cogs {
    display: block;
    margin-bottom: 2rem;
}

.maintenancePage h2 {
    font-size: 30px;
    line-height: 40px;
}
.maintenancePage a {
    font-size: 25px;
    margin-top: 50px;
    display: block;
}

@-webkit-keyframes fa-spin-one {
    0% {
        transform: translateY(-2rem) rotate(0deg);
    }

    100% {
        transform: translateY(-2rem) rotate(-359deg);
    }
}

@keyframes fa-spin-one {
    0% {
        transform: translateY(-2rem) rotate(0deg);
    }

    100% {
        transform: translateY(-2rem) rotate(-359deg);
    }
}

.maintenancePage .fa-spin-one,
.maintenancePage .what-is-up .spinny-cogs .fa:nth-of-type(1) {
    -webkit-animation: fa-spin-one 1s infinite linear;
    animation: fa-spin-one 1s infinite linear;
}

@-webkit-keyframes fa-spin-two {
    0% {
        transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
    }

    100% {
        transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
    }
}

@keyframes fa-spin-two {
    0% {
        transform: translateY(-0.5rem) translateY(1rem) rotate(0deg);
    }

    100% {
        transform: translateY(-0.5rem) translateY(1rem) rotate(-359deg);
    }
}

.maintenancePage .fa-spin-two,
.maintenancePage .what-is-up .spinny-cogs .fa:nth-of-type(3) {
    -webkit-animation: fa-spin-two 2s infinite linear;
    animation: fa-spin-two 2s infinite linear;
}


.overlayloader .loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #000000; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin .8s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .overlayloader{
top: 0;
display: flex;
justify-content: center;
align-items: center;
  }