.PinturaRoot[data-env~=landscape][data-env~=has-navigation] {
    border: 1px solid #ddd;
    margin: 50px;
    border-radius: 10px;
}

.imageeditor {
    margin: 120px;
    background: #fff;
}

.PinturaRoot>.PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly):not(.PinturaDropdownButton) {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
}

.PinturaRoot>.PinturaNav .PinturaButton:not(.PinturaButtonIconOnly):not(.PinturaDropdownIconOnly):not(.PinturaDropdownButton) span {
    font-family: 'Graphik Trial Regular';
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
}


@media (max-width:575px){
    .imageeditor {
        margin: 70px 0px;
    }

    .PinturaRoot .PinturaButtonExport{
        background-color: #000;
        color: #fff;
    }
    .PinturaRoot .PinturaButtonExport:hover{
        background-color: #000;
        color: #fff;
    }
  
    
}