.contact-us,
.privacy-policy,
.faq_page {
  font-family: "Graphik Trial Regular";
}
.contact-us {
  padding: 80px 0;
}
.contact-us .form-control {
  box-shadow: none !important;
  border-radius: 12px;
  border-color: #ddd;
  font-size: 14px;
  min-height: 50px;
  letter-spacing: 0;
}
.contact-us .form-control:focus {
  border-color: #000;
}
.form-label {
  letter-spacing: 0;
}
.contact-us h1,
.privacy-policy h1,
.faq_page h1 {
  font-size: 80px;
  margin-bottom: 20px;
  font-weight: 600;
}
.faq_page h3 {
  font-size: 36px;
  font-weight: bolder;
  text-align: center;
  color: #000;
}
.fqa_q_box {
  margin: 25px 0;
  padding: 0 30px;
  color: #000000;
  font-size: 1rem;
  line-height: 1.5rem;
}

.fqa_q_box p strong {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
}
.cont-left {
  max-width: 80%;
}
.cont-left p {
  font-size: 24px;
  font-family: "Graphik Trial Regular";
  font-weight: 400;
  line-height: 1.7;
}
.theme-btn {
  min-width: 120px;
}
.cont-dash .col-sm-6 {
  max-width: 800px;
  width: 100%;
}
.cont-dash .cont-left {
  max-width: 100%;
  margin-bottom: 30px;
}
.cont-dash .cont-right {
  max-width: 800px;
}

/*  Responsive */
@media (max-width: 1440px) {
  .contact-us h1,
  .privacy-policy h1,
  .faq_page h1 {
    font-size: 70px;
  }
}
@media (min-width: 768px) {
  .contact-us {
    margin: 30px 0;
  }
  .cont-dash .contact-us h1 {
    font-size: 60px;
  }
}
@media (max-width: 768px) {
  .contact-us h1,
  .privacy-policy h1,
  .faq_page h1 {
    font-size: 1.7rem;
  }
  .contact-us {
    margin-bottom: 30px;
  }
  .cont-left {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .cont-left p {
    font-size: 16px;
    line-height: 1.7;
  }
}
